import React from 'react'
import styles from './Privacy.module.scss'

const privacy_items = [
    {
        description: `
            <p>
                株式会社grid.io（以下「当社」といいます。）利用者の個人情報の保護の重要性を認識し、以下のプライバシーポリシー（以下「本ポリシー」といいます）に基づき、個人情報の適切な取り扱いと保護に努めます。本ポリシーは、当社が提供するすべてのサービス（以下「本サービス」といいます）に適用されます。
            </p>
            <h3 class="subhead">個人情報の定義</h3>
            <p>
                本ポリシーにおいて「個人情報」とは、氏名・住所・電話番号・メールアドレス等、特定個人を識別できる情報、その情報と容易に照合可能な情報をいいます。
            </p>
            <h3 class="subhead">個人情報の取得、利用、提供</h3>
            <p>
                当施設の事業内容及び事業実態に応じた、個人情報を収集・利用・提供するにあたって、当施設が定めた規定に従い 適切に取り扱います。
            </p>
            <p>
                会員登録に必要な個人情報をご提出いただきます。</br>
                会員様の本人確認、料金の請求、サービス内容の変更、中止、その他サービスの提供に係わる事。</br>
                当施設のサービスに関する改善、叉は新たなサービスの開発を行なうこと。</br>
                お客様から同意を得た範囲内で利用する事。</br>
                その他、クッキー、アクセスログ等、自動的に取得する情報。
            </p>
            <h3 class="subhead">個人情報の安全管理措置</h3>
            <p>
                当施設は、個人情報の紛失、破壊、改ざんおよび漏洩等の防止のため、不正アクセス、コンピューターウイルス等に対する適切な規定を定め安全対策を実施します。
            </p>
            <p>
                当社は、以下の場合を除き、利用者の個人情報を第三者に提供することはありません。</br>
            </p>
            <ul>
                <li>利用者の同意がある場合</br></li>
                <li>法令に基づく場合</br></li>
                <li>人の生命、身体または財産の保護のために必要な場合</br></li>
                <li>業務委託先に提供する場合（この場合、適切な管理および監督を行います）</li>
            </ul>
            <h3 class="subhead">個人情報の正確性の確保</h3>
            <p>
                当施設は、利用目的の達成に必要な範囲で個人情報を正確かつ最新の状態に保つよう、適切な措置を講じます。また、利用する必要がなくなったときは、当該個人データを遅滞なく消去するよう努めます。
            </p>
            <h3 class="subhead">個人情報保護体制の継続的改善</h3>
            <p>
                個人情報保護に関する社内規程および関連規程類、従業員教育、安全対策、組織体制等の継続的な見直しおよびこれに基づく個人情報の取扱い（プライバシーポリシー）の見直しを図り、個人情報の保護水準の向上に努めます。
            </p>
            <h3 class="subhead">クッキー（Cookie）等の利用について</h3>
            <p>
                利用者の利便性向上および本サービスの改善のため、クッキーや類似の技術を利用して情報を収集する場合があり、利用者はブラウザの設定によりクッキーの受け入れを拒否することができますが、その場合、本サービスの一部が正常に機能しない可能性があります。
            </p>
            <h3 class="subhead">プライバシーポリシーの変更</h3>
            <p>
                当社は、本ポリシーの内容を予告なく変更することがあります。変更後のポリシーは、当社ウェブサイト上に掲載した時点から効力を生じるものとします。定期的に本ページをご確認ください。
            </p>
            <h3 class="subhead">お問合せ窓口</h3>
            <p>
                本ポリシーに関するお問合せは下記までお願いします。</br>
                住所：〒180-0004　東京都武蔵野市吉祥寺本町1-24-7　吉祥寺光ビル6F</br>
                名称：株式会社grid.io</br>
                電話番号：0422-27-6843</br>
                メールアドレス：<a href="mailto:info@gridio.co">info@gridio.co</a>
            </p>
        `
    }
]

const PrivacyItem = ({item}) => {
    const description = item.description;

    return (
        <div className={styles.items} dangerouslySetInnerHTML={{__html: description}} />
    );
};

export default () => (
    <section className={`${styles.privacy} l-wrapper l-container`}>
        <div>
            <h2 className={`head`}>プライバシーポリシー</h2>
            {privacy_items.map(item => <PrivacyItem item={item} />)}
        </div>
    </section>
)
